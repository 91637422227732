<script>
import RegionBase from './RegionBase'
import { asiaManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'asia',
      activeIntro: 'Giveaways for Asia',
      gameList: asiaManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - Asia',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
